<template>
  <div>

    <div v-if="error">
      <p style="color: red;">{{ error }}</p>
    </div>

    <b-modal
      ref="donation-modal"
      hide-footer
      hide-header
    >

      <div v-if="current_page === 'DONATION' && donation_page">
        <h4 class="my-4">{{ donation_page.name }}</h4>

        <div v-if="show_select_type" class="radio-button-group mt-4">

          <div v-if="donation_page.fields.activate_one_time">
            <input v-model="selected_type" type="radio" id="ONE_TIME" name="type" value="ONE_TIME">
            <label class="radio-button" for="ONE_TIME">
              <span class="radio-custom"></span>
              <span class="radio-label">
                {{ $t('DONATION.ONE_TIME') }}
              </span>
            </label>
          </div>

          <div v-if="donation_page.fields.activate_subscription">
            <input v-model="selected_type" type="radio" id="SUBSCRIPTION" name="type" value="SUBSCRIPTION">
            <label class="radio-button" for="SUBSCRIPTION">
              <span class="radio-custom"></span>
              <span class="radio-label">
                {{ $t('DONATION.SUBSCRIPTION') }}
              </span>
            </label>
          </div>
    
        </div>
    
        <div class="single-selected my-8" v-else-if="show_one_time">
          <p>{{ $t('DONATION.ONE_TIME') }}</p>
        </div>

        <div class="single-selected my-8" v-else-if="show_subscription">
          <p>{{ $t('DONATION.SUBSCRIPTION') }}</p>
        </div>


        <div v-if="has_fixed">
          <h5>{{ $t('DONATION.SELECT_FIXED') }}</h5>
          <div class="radio-button-group">

            <div 
              v-for="(option, index) in items"
              :key="option.id"
              :class="{ selected: selectedOption === option.amount }">

              <input v-model="selectedOption" type="radio" :id="`option-${option.id}`" name="option" :value="option.amount">
              <label class="radio-button" :for="`option-${option.id}`">
                <span class="radio-custom"></span>
                <span class="radio-label">{{ option.amount }}</span>
              </label>

            </div>
            
          </div>

        </div>
        
        <div v-if="has_custom">
          
          <h5 class="mt-4" v-if="has_fixed">{{ $t('DONATION.SELECT_CUSTOM') }}</h5>
          <div class="radio-button-group">
            <input v-model="selectedOption" type="radio" id="option1" name="option" value="custom">
            <label class="radio-button" for="option1">
              <span class="radio-custom"></span>
              <span class="radio-label">

                <div v-if="selectedOption === 'custom'" class="amount-input">
                  <input
                    class="custom_amount"
                    v-model="input_amount"
                    placeholder="Ange belopp"
                    min="0"
                  >
                </div>
              </span>
            </label>
            
          </div>
          
        </div>
      </div>
      

      <div v-else-if="current_page === 'PERSON_DETAILS'">
        <FormPagePersonDetails 
          :form_page="person_details_page"
          @updated="person_details_updated"
        />
      </div>

      <div v-else-if="current_page === 'CONFIRM'">
        <FormPageConfirm
          :form_page="confirm_page"
        />
      </div>

      <div v-else-if="current_page === 'PAYMENT'">
        <SelectPaymentPage
          v-if="shop_order"
          :prop_shop_order="shop_order"
        />
        <div class="d-flex justify-content-center mb-3" v-else>
          <b-spinner label="Loading..."></b-spinner>
        </div>
      </div>

      <div class="center-container">
        <memlist-contrast-button
          :text="$t('DONATION.CONTINUE')"
          :bgColor="form?.style?.button_color || '#0059b2'"
          @click="continue_page(0)"

          v-if="current_page !== 'PAYMENT'"
        />
      </div>


    </b-modal>
    
    <div class="center-container" style="margin-top: 64px;">

      <memlist-contrast-button
        v-if="form && form.style"
        :text="form?.texts?.button_text || 'Donera'"
        :bgColor="form?.style?.button_color || '#0059b2'"
        @click="$refs['donation-modal'].show()"
      />

      <div class="d-flex justify-content-center mb-3" v-else>
        <b-spinner label="Loading..."></b-spinner>
      </div>

    </div>
    
  </div>
</template>

<script>
import axios from 'axios';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import StripePaymentLinkComponent from './StripePaymentLinkComponent.vue';
import FormPagePersonDetails from '@/view/pages/public/forms/pages/FormPagePersonDetails.vue';
import FormPageConfirm from '@/view/pages/public/forms/pages/FormPageConfirm.vue';
import SelectPaymentPage from '@/view/pages/members/payment/SelectPaymentPage.vue';


export default {
  name: 'TestDonationPopup',
  props: [],
  mixins: [ toasts ],
  components: {
    StripePaymentLinkComponent,
    FormPagePersonDetails,
    FormPageConfirm,
    SelectPaymentPage,
  },
  computed: {
    show_select_type() {

      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_one_time && this.donation_page.fields.activate_subscription;
    },

    show_one_time() {
      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_one_time;
    },
    
    show_subscription() {
      if (!this.donation_page) {
        return false;
      }

      return this.donation_page.fields.activate_subscription;
    },
    
  },
  data() {
    return {

      linkstr: null,

      shop_order: null,

      error: null,

      current_page: 'DONATION',

      selected_type: 'ONE_TIME', /// ONE_TIME, SUBSCRIPTION

      input_amount: null,

      form: null,

      items: [],

      custom: {},

      has_fixed: false,
      has_custom: false,

      selectedOption: null,

      person_details_page: null,
      person_details_data: null,
      
      confirm_page: null,

      donation_page: null,
    };
  },
  async mounted() {

    this.linkstr = this.$route.params.linkstr;

    this.form = await this.load_form(this.linkstr);

    this.setup_page();
  },
  watch: {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    
    person_details_updated(data) {
      this.person_details_data = data;
    },

    async load_form(linkstr) {
      const res = await axios.get(`/form/${linkstr}`);

      if (res.status === 200) {
        console.log('data', res.data);

        return res.data;
      }

      return null;
    },
    
    continue_page(index) {
      if (this.current_page === 'DONATION') {
        if (this.person_details_page) {
          this.current_page = 'PERSON_DETAILS'
        }
        else if (this.confirm_page) {
          this.current_page = 'CONFIRM';
        }
        else {
          this.current_page = 'PAYMENT';
        }
      }
      else if (this.current_page === 'PERSON_DETAILS') {
        if (this.confirm_page) {
          this.current_page = 'CONFIRM';
        }
        else {
          this.current_page = 'PAYMENT';
        }
      }
      else if (this.current_page === 'CONFIRM') {
        this.current_page = 'PAYMENT';
      }

      if (this.current_page === 'PAYMENT') {
        this.create_order_if_not_exists();
      }
    },

    async create_order_if_not_exists() {
      if (this.shop_order) {
        return;
      }

      const data = [];

      data.push({
        type: 'DONATION',
        amount: this.selectedOption === 'custom' ? this.input_amount : parseInt(this.selectedOption+''),
        selected_type: this.selected_type,
        selected_option: this.selectedOption,
      });

      if (this.person_details_page) {
        person_details_data.type = 'PERSON_DETAILS';
        data.push(person_details_data);
      }


      const res = await axios.post(`/form/public/${this.linkstr}`, {
        form_data: data,
        visitor_id: this.form.visitor_id
      });

      if (res.status === 201) {
        this.shop_order = res.data.shop_order;
        this.current_page = 'PAYMENT';

        
      }
      else {
        console.error('unable to create shop order');
        this.error = 'UNABLE_TO_CREATE_ORDER';
      }
    },

    setup_page() {

      this.donation_page = this.form.pages.find(item => item.type === 'DONATION');

      if (!this.donation_page) {
        this.error = 'DONATION_PAGE_ERROR_NOT_FOUND';
        return;
      }

      this.items = this.donation_page.fields.items.filter(item => item.type === 'FIXED');

      this.has_fixed = this.items.length > 0;

      this.custom = this.donation_page.fields.items.find(item => item.type === 'CUSTOM');

      this.has_custom = !!this.custom;

      this.person_details_page = this.form.pages.find(item => item.type === 'PERSON_DETAILS');

    },
  }
};
</script>


<style lang="css" scoped>

.center-container {
  margin-top: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-selected {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 1px solid #0085eb; /* Default border color is gray */
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff; /* Default background color */
  transition: background-color 0.2s, border-color 0.2s;
  background-color: #e3eeff;
}

.single-selected p {
  margin-top: 10px;
  font-size: 16px;
  color: #0085eb;
  font-weight: bold;
}

h4 {
  text-align: center;
}

h5 {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
}

/* CSS Code */
.radio-button-group {
  display: flex;
  flex-direction: column;
}

.radio-button {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #ccc; /* Default border color is gray */
  border-radius: 6px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #fff; /* Default background color */
  transition: background-color 0.2s, border-color 0.2s;
}

.radio-button:hover {
  background-color: #f9f9f9;
}

.radio-button input[type="radio"] {
  display: none;
}

.radio-custom {
  width: 22px;
  height: 22px;
  border: 2px solid #ccc; /* Match the button's border color */
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  transition: border-color 0.2s;
}

.radio-custom::after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: #0085eb;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  opacity: 0;
  transition: opacity 0.2s;
}

.radio-label {
  font-size: 16px;
  transition: color 0.2s;
}

input[type="radio"] {
  display: none;
}

/* Styles when the radio button is selected */
input[type="radio"]:checked + .radio-button {
  background-color: #e6f0ff; /* Highlighted background color */
  border-color: #0085eb;     /* Highlighted border color */
}

input[type="radio"]:checked + .radio-button .radio-custom {
  border-color: #0085eb; /* Change radio indicator border color */
}

input[type="radio"]:checked + .radio-button .radio-custom::after {
  opacity: 1;
}

input[type="radio"]:checked + .radio-button .radio-label {
  font-weight: bold;
  color: #005bb5;
}

.amount-input {
  width: 100%;
}

.amount-input input {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.amount-input input:focus {
  outline: none;
  border-color: #0085eb;
  box-shadow: 0 0 5px rgba(0, 133, 235, 0.5);
}

.custom_amount {
  font-weight: 400;
}

</style>